import React, { Component } from "react";

import { Card, CardBody, Row, CardTitle, Col, Button, Modal } from "reactstrap";


// import Graph from "./Graph";

import axios from "axios";

import { nodeBaseURL } from "../../ApiURL";

import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import ComparisonGraph from "components/HourWiseComparison/ComparisonGraph";
import { getOutletTime } from "utilFunctions/getOutletTime";
import handleDateRange from 'utilFunctions/handleDateRange';

const mapStateToProps = (state) => {
  return {
    act: jwt_decode(state.act.act),
    time: state.time.time
  };
};

class EntryUnmanned extends Component {
  constructor(props) {
    super(props);
    this.state = {
      exampleModal: false,
      data: 'Loading...',
      time: this.props.time,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      outlet: this.props.outlet,
      daterange: "day",
      userPosition: localStorage.getItem('rootState').position
    };
    this._ismounted = true;
  }

  fetchData = (nextProps) => {
    this._ismounted && axios.get(
      nodeBaseURL +
      `billing_manned/list/${nextProps.outlet}/week?fromdate=${nextProps.startDate}&tilldate=${nextProps.endDate}`,
      {
        headers: {
          'Authorization': `bearer ${localStorage.getItem('act')}`
        }
      }
    )
      .then((res) => {
        this._ismounted && this.setState({
          data: res.data.reverse()[0] ? `Last Unmanned at ${getOutletTime(res.data[0].timestamp)}` : 'Last Unmanned Unavailable',
          daterange: handleDateRange(nextProps.time)
        });
      })
      .catch(err => {

      });
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

    if (this.props !== nextProps) {
      this._ismounted && this.setState({
        time: nextProps.time,
      });
      this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  toggleModal = (state) => {
    this._ismounted && this.setState({
      [state]: !this.state[state],
    });
  };


  render() {
    return (
      <Card className="card-stats billing-counter-card">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                Billing Counter Unmanned
              </CardTitle>
              {/* <ToastAlert /> */}
              <div className="mt-3 mb-2">
                <Row>
                  <Col>
                    <span className="h5 font-weight-bold mb-0pl-3">
                      <span className="h5 text-info">
                      </span>{" "}
                      {this.state.data}
                    </span>
                  </Col>
                  {/* <Col lg='4' xl='5'>
                    <span className="h5 text-info">
                      Manned
                    </span>
                    <span className="h4 font-weight-bold mb-0pl-3">
                      <span className="h4 text-info">
                      </span>{" "}
                      {this.state.data.occupied}
                    </span>
                  </Col>
                  <Col lg='4' xl='5'>
                    {/* <Col> */}
                  {/* <span className="h5 text-info">
                      Unmanned
                    </span>
                    <span className="h4 font-weight-bold mb-0pl-3">
                      <span className="h4 text-info">
                      </span>{" "}
                      {this.state.data.unoccupied}
                    </span> */}
                  {/* </Col> */}
                </Row>
              </div>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                <i className="fas fa-male" />
              </div>
            </Col>
          </Row>
          <div className="mt-2 mb-0 text-sm">
            <div className="mt-1">
              <Button
                color="secondary"
                outline
                type="button"
                size="sm"
                onClick={() => this.toggleModal("exampleModal")}
              >
                <span className="text-info">
                  <i className="fa fa-question-circle" /> Learn More
                </span>{" "}
              </Button>
            </div>
            <Modal
              className="modal-dialog-centered"
              isOpen={this.state.exampleModal}
              toggle={() => this.toggleModal("exampleModal")}
              size="lg"
            >
              <div className="modal-header">
                <span>
                  <h4 className="modal-title" id="exampleModalLabel">
                    Billing Counter Unmanned
                  </h4>
                  <br />
                  <h4 className="h4 text-muted">({this.state.daterange})</h4>
                </span>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("exampleModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
                {/* <Row>
                  <Col>
                    <Graph
                      outlet={this.state.outlet}
                      time={this.state.time}
                      startDate={this.props.startDate}
                      endDate={this.props.endDate}
                    />
                  </Col>
                </Row> */}
                <ComparisonGraph
                  dataType='Count'
                  route='billing_manned/hourwise'
                  outletCode={this.props.outlet}
                />
              </div>
            </Modal>
          </div>
        </CardBody >
      </Card >
    );
  }
}

export default connect(
  mapStateToProps,
)(EntryUnmanned);