const featuresJSON = {
    "CI.PC": {
        title: 'People Counter',
        icon: 'fas fa-users',
        benefit: "",
        description: 'Logging number of customers visting different aisle in the store'
    },
    "CI.CCM": {
        title: "Customer Conversion Monitoring",
        icon: '',
        benefit: "",
        description: 'Logging the number of people actully buying items in your store w.r.t. to the number of customer visiting your store',
    },
    "CI.AE": {
        title: "Age Estimation",
        icon: 'fas fa-users',
        benefit: "",
        description: 'Logging the estimated age of the customers entering the Store',
    },
    "CI.EL": {
        title: "Entry Logs",
        icon: 'fas fa-door-open',
        benefit: "",
        description: 'Logging number of customers entering the store '
    },
    "CI.SH": {
        title: "Store Heatmaps",
        icon: 'fas fa-map-signs',
        benefit: "",
        description: 'Shows how much each aisle in the store were most visited by the customers '
    },
    "CI.GRM": {
        title: "Gender Ratio Monitoring",
        icon: 'fas fa-venus-mars',
        benefit: "",
        description: 'Tells the gender ratio of the customer visiting the Store'
    },
    "CI.PRHM": {
        title: " Peak Rush Hour Monitoring",
        icon: '',
        benefit: "",
        description: 'Tells the time in which maximum customers visiting in the Store'
    },
    "SAD.SD": {
        title: "Shoplifting Detection",
        icon: 'fas fa-exclamation-triangle',
        benefit: "",
        description: 'Sends alerts if the Shoplifting Action is detected'
    },
    "SAD.VD": {
        title: "Violence Detection",
        icon: 'fas fa-gun',
        benefit: "",
        description: 'Sends alerts if the Violence is detected.'
    },
    "SEPM.PU": {
        title: "Phone Usage",
        icon: 'fas fa-mobile-alt',
        benefit: "",
        description: 'Check if phone is used by the employee on the Billing Counter'
    },
    "SEPM.SEU": {
        title: "Store Entrance Unmanned",
        icon: 'fas fa-store',
        benefit: "",
        description: 'Check whether the store entrance is being manned by a suitable person or not'
    },
    "SEPM.OCT": {
        title: 'Store Open and Close time',
        icon: 'fas fa-store',
        benefit: "",
        description: 'Logging the store opening and closing times'
    },
    "TA.CCTV": {
        title: "CCTV Tampering Alert",
        icon: 'fas fa-exclamation',
        benefit: "",
        description: 'Monitor any tampering that are done to the CCTV infrastructure.'
    },
    "CS.PG": {
        title: "Person Gathering",
        icon: 'fas fa-users',
        benefit: "",
        description: 'Alert if more than a threshold number of people are present in the store'
    },
    "CS.MD": {
        title: "Mask Detection",
        icon: 'ni ni-check-bold',
        benefit: "",
        description: 'Check if your customers are wearing mask in your store'
    },
    "CS.SD": {
        title: "Social Distancing",
        icon: 'fas fa-people-arrows',
        benefit: "",
        description: 'Check if proper Social Distance is maintained by your customers in your store'
    },
    "CS.SA": {
        title: "Sanitation Alerts",
        icon: 'fas fa-hands-bubbles',
        benefit: "",
        description: 'Alert to sanitise the store after threshold number of peoples have vistited the store'
    },
    "EM.EWD": {
        title: "Electricity Wastage Detection",
        icon: 'fas fa-charging-station',
        benefit: "",
        description: 'Detect if the lights are still ON after business hours'
    },
    "KHD.FMM": {
        title: "Floor Mopping Monitoring",
        icon: 'fas fa-broom',
        benefit: "",
        description: 'Checking if at regular intervel of time unit cleaning process is taking place or not'
    },
    "KHD.HWT": {
        title: "Hand-Wash Tracking",
        icon: 'fas fa-hands-wash',
        benefit: "",
        description: 'Hand wash duration detection (including compliance to 20 second rule)'
    },
    "ZS.VN": {
        title: "Veg / Non-Veg",
        icon: 'fas fa-drumstick-bite',
        benefit: "",
        description: 'Checking based on Uniform if the staff are in their designated Zones'
    },
    "ST.STP": {
        title: "Security Team Presence",
        icon: 'fas fa-user-shield',
        benefit: "",
        description: 'Check if the security team is present at your store '
    },
    "OCT.KT": {
        title: 'KOT Tracking',
        icon: 'fas fa-ticket-alt',
        benefit: "",
        description: 'Logging Order Number for the future reference'
    },
    "OCT.MBC": {
        title: 'Manned/Unmanned Billing Counter',
        icon: 'fas fa-male',
        benefit: "",
        description: 'Checking if the Front Counter is Manned or Unmanned'
    },
    "HI.FNB": {
        title: 'Hygiene Index',
        icon: 'fas fa-hand-sparkles',
        benefit: "",
        description: 'Hygiene Index is calculated based on the a number of factors'
    },
    "KPPE.HD": {
        title: 'Hairnet Detection',
        icon: 'ni ni-check-bold',
        benefit: "",
        description: 'Checking if any Hairnet deviation is taking place'
    },
    "KPPE.FMD": {
        title: 'Face Mask Detection',
        icon: 'ni ni-check-bold',
        benefit: "",
        description: 'Checking if any Face Mask deviation is taking place'
    },
    "KPPE.GD": {
        title: 'Gloves Detection',
        icon: 'ni ni-check-bold',
        benefit: "",
        description: 'Checking if any Glove deviation is taking place'
    },
    "WI.RO": {
        title: 'Room Occupancy',
        icon: 'fas fa-arrow-circle-up',
        benefit: "",
        description: 'Logging the number of people present in a given camera\'s field of view at any given point of time.'
    },
}

export default featuresJSON;