import React, { Component } from 'react';
import featureList from 'jsons/featureList';
import featuresJSON from 'jsons/featuresJSON';
import { getNotOptedFeatures } from 'utilFunctions/getNotOptedFeatures'
import { connect } from 'react-redux';
import {
    Row
} from 'reactstrap'

const mapStateToProps = (state) => {
    return {
        outletCode: state.outletCode.outletCode,
        companyServices: state.services.companyServices,
    };
}

class ExploreMoreFeatures extends Component {

    constructor(props) {
        super(props)
        this.state = {
            featureCodes: getNotOptedFeatures(featureList, this.props.companyServices[this.props.outletCode] ? this.props.companyServices[this.props.outletCode].services : [])
        }
    }
    render() {
        return (
            <div>
                {
                    getNotOptedFeatures(featureList, this.props.companyServices[this.props.outletCode] ? this.props.companyServices[this.props.outletCode].services : [])
                        .map((item, key) => {
                            return (
                                <Row key={key}>
                                    {featuresJSON[item].title}
                                </Row>
                            )
                        })
                }
            </div>
        );
    }
}

export default connect(
    mapStateToProps,
)(ExploreMoreFeatures);