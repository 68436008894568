import HygieneIndex from "components/Dashboard/HygieneIndex/HygieneIndex";
import Demographics from "components/Dashboard/Demographics/Demographics";
import PPECheck from "components/Dashboard/PPECheck/PPECheck";
import EntryExit from "components/Dashboard/EntryExit/EntryExit";
import KOT from "components/Dashboard/KOT/KOT";
import BillingUnmanned from "components/BillingUnmanned/BillingUnmannedCard";
import PeopleCount from "components/Dashboard/PeopleCount/PeopleCount";
import Handwash from "components/Dashboard/Handwash/Handwash";
import SocialDistancing from "components/Dashboard/SocialDistancing/SocialDistancing";
import OpenClose from "components/Dashboard/OpenClose/OpenClose";
import OccupancyCard from 'components/Dashboard/Occupancy/OccupancyCard';
import ElectricityWastage from "components/ElectricityWastage/EWCard";
import Heatmap from "components/Heatmap/HeatmapCard";
import FLoorMopping from "components/FloorMopping/FMCard";
import PhoneUsage from 'components/PhoneUsage/PUCard';
import AgeEstimationCard from 'components/Demographics/AgeEstimation/AECard';

// import NotificationsCard from "components/Dashboard/Notifications/Notifications"

import getFlatArray from 'utilFunctions/getFlatArray';
import SuspicionCountCard from "components/SuspicionCount/SuspicionCountCard";
import CCTVTampering from "components/CCTVTampering/CCTVTamperingCard";
import EntranceManned from "components/EntranceManned/EntranceMannedCard";
import SecurityPresence from "components/SecurityPresence/SecurityPressenceCard";
import CSICard from "components/CustomerSatisfaction/CSICard";
import CACard from "components/CustomerAttended/CACard";
import BagPrintCard from "components/BagPrint/BagPrintCard";
import PackagingPrintIndexCard from "components/PackagingPrint/PackagingPrintIndexCard";
import PackagingEfficiencyCard from "components/PackagingPrint/PackagingEfficiencyCard";

const getDashboardCards = (services) => {
    services.sort();
    let dynamicCards = services.map((item) => {
        let component;
        switch (item) {
            case 'CI.CSI':
                component = CSICard;
                break;
            case 'CI.CU':
                component = CACard;
                break;
            case 'CI.PC':
                component = PeopleCount;
                break;
            case 'CI.EL':
                component = EntryExit;
                break;
            case 'SEPM.OCT':
                component = OpenClose;
                break;
            case 'OCT.MBC':
                component = BillingUnmanned;
                break;
            case 'CS.SD':
                component = SocialDistancing;
                break;
            case 'OCT.KT':
                component = KOT;
                break;
            case 'WI.RO':
                component = OccupancyCard;
                break;
            case 'KHD.HWT':
                component = Handwash;
                break;
            case 'CI.GRM':
                component = Demographics;
                break;
            case "EM.EWD":
                component = ElectricityWastage;
                break;
            case "SAD.SD":
                component = SuspicionCountCard;
                break;
            case 'CI.SH':
                component = Heatmap;
                break;
            case 'KHD.FMM':
                component = FLoorMopping;
                break;
            case 'SEPM.PU':
                component = PhoneUsage;
                break;
            case 'CI.AE':
                component = AgeEstimationCard;
                break;
            case 'TA.CCTV':
                component = CCTVTampering;
                break;
            case 'SEPM.SEU':
                component = EntranceManned;
                break;
            case 'ST.STP':
                component = SecurityPresence;
                break;
            case 'WI.PC':
                component = BagPrintCard;
                break;
            case 'WI.PI':
                component = PackagingPrintIndexCard
                break;
            case 'WI.PE':
                component = PackagingEfficiencyCard
                break;
            default:
                break;
        }
        return component;
    });
    let hardCards = [];
    if (services.includes('HI.FNB') || services.includes('HI.R')) {
        hardCards.push(HygieneIndex);
    }
    if (services.includes('KPPE.HD') || services.includes('KPPE.FMD') || services.includes('KPPE.GD')) {
        hardCards.push(PPECheck);
    }
    let finalCards = hardCards.concat(dynamicCards);
    finalCards = finalCards.filter((item) => item !== undefined);
    return getFlatArray(finalCards);
};

export default getDashboardCards;