import React from "react";
import ComponentLogs from 'components/LogsL/ComponentLogs';

import axios from "axios";
import { nodeBaseURL } from "../../ApiURL";

import handleDateRange from "utilFunctions/handleDateRange";
import { connect } from 'react-redux';
import { getConvertedTime, hour24Format, onlyDateFormat } from "utilFunctions/getConvertedTime";

const mapStateToProps = (state) => {
    return {
        outlet: state.outletCode.outletCode,
        time: state.time.time,
        companyServices: state.services.companyServices
    };
};

class CSILogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logsData: [],
            logsColumnInfo: [
                {
                    dataField: "timestamp",
                    text: "timeframe",
                    sort: true,
                },
                {
                    dataField: "happy",
                    text: "happy",
                    sort: false,
                },
                {
                    dataField: "sad",
                    text: "sad",
                    sort: false,
                },
                {
                    dataField: "angry",
                    text: "angry",
                    sort: false,
                },
                {
                    dataField: "confused",
                    text: "confused",
                    sort: false,
                },
                {
                    dataField: "disgusted",
                    text: "disgusted",
                    sort: false,
                },
                {
                    dataField: "surprised",
                    text: "surprised",
                    sort: false,
                },
                {
                    dataField: "calm",
                    text: "calm",
                    sort: false,
                },
                {
                    dataField: "fear",
                    text: "fear",
                    sort: false,
                }
            ],
            logsName: 'Customer Satisfaction',
            outlet: props.outlet,
            time: props.time,
            daterange: "Loading...",
            timeZone: props.companyServices[props.outlet] ? props.companyServices[props.outlet].timezone : null
        };
        this._ismounted = true;
    }

    fetchData = (nextProps) => {
        this._ismounted && axios.get(
            nodeBaseURL +
            `customer_emotions/list/${nextProps.outlet}/${nextProps.time}`,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('act')}`
                }
            }
        )
            .then((res) => {
                var x = res.data.map((r) => ({
                    timestamp: getConvertedTime(r.timestamp, this.state.timeZone, nextProps.time === 'day' ? hour24Format : onlyDateFormat),
                    happy: r.HAPPY,
                    sad: r.SAD,
                    angry: r.ANGRY,
                    confused: r.CONFUSED,
                    disgusted: r.DISGUSTED,
                    surprised: r.SURPRISED,
                    calm: r.CALM,
                    fear: r.FEAR,
                }));
                this._ismounted && this.setState({ logsData: x.reverse(), daterange: handleDateRange(nextProps.time) });

                if (x.length === 0) {
                    this._ismounted && this.setState({
                        daterange: `No data is available for ${nextProps.time}`
                    });
                }
            })
            .catch(err => {
                this._ismounted && this.setState({ daterange: "Some Error Occured Please contact us at support@oureye.ai" });
            });
    };

    componentDidMount() {
        this.fetchData(this.state);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this._ismounted && this.setState({ daterange: 'Loading...', time: nextProps.time, timeZone: nextProps.companyServices[nextProps.outlet] ? nextProps.companyServices[nextProps.outlet].timezone : null });
            this._ismounted && this.fetchData(nextProps);
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    render() {
        return (
            <>
                <div>
                    <ComponentLogs
                        logsData={this.state.logsData}
                        logsColumnInfo={this.state.logsColumnInfo}
                        logsName={this.state.logsName}
                        daterange={this.state.daterange}
                    />
                </div>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
)(CSILogs);
