import React from "react";
// react library for routing
// reactstrap components
import {
  Button,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  CardBody,
  Container,
  Row,
  Col,
  Modal
} from "reactstrap";

import axios from 'axios'
import { nodeBaseURL } from "ApiURL";

// core components
import ViewImagesNavbar from "components/Navbars/ViewImagesNavbar.js";
import ViewImagesHeader from "components/Headers/ViewImagesHeader.js";
import AuthFooter from "components/Footers/AuthFooter.js";

class ViewImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showImages: false,
      modalState: false,
      images: []
    };
    this._ismounted = true;
  }

  verifyOTP = async () => {
    if (this.state.OTP === this.state.userInputOTP) {
      this.setState({
        modalState: false,
        showImages: true,
        OTP: null,
        userInputOTP: null,
        errMsg: null,
      });
      await this.fetchData();
    } else {
      this.setState({
        errMsg: "Invalid OTP"
      });
    }
  }

  toggleModal = (OTP) => {
    this.setState((prevState) => {
      return ({
        OTP: OTP ? OTP : prevState.OTP,
        modalState: !prevState.modalState
      })
    });
  }

  fetchData = () => {
    this._ismounted && axios.get(
      nodeBaseURL + 'test_aws/list/',
      {
        headers: {
          'Authorization': `bearer ${localStorage.getItem('act')}`
        }
      }
    )
      .then((res) => {
        this.setState({
          images: res.data
        });
      })
      .catch(err => {
        this.setState({
          images: []
        });
      });
  }

  handleInputChange = (e) => {
    this._ismounted && this.setState({
      [e.target.name]: e.target.value
    });
  };

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    return (
      <>
        <ViewImagesNavbar />
        <div className="main-content">
          {this.state.showImages ?
            null
            :
            <ViewImagesHeader toggleModala={this.toggleModal} />
          }
          <section className="py-6 pb-9 bg-default">
            {this.state.showImages ?
              <Container fluid>
                <Row>
                  <Col>
                    <h1 className="text-center text-white mb-2">
                      <strong>
                        Images
                      </strong>
                    </h1>
                    {
                      this.state.images.map((image, index) => {
                        return (
                          <div key={index} className="col-md-4 col-sm-6 col-xs-12">
                            <div className="card">
                              <CardBody>
                                <img src={image} width='100%' alt="Bag Not Printed" />
                              </CardBody>
                            </div>
                          </div>
                        );
                      }
                      )
                    }
                  </Col>
                </Row>
              </Container>
              :
              null
            }
          </section>
          <section className="section section-lg pt-lg-0 mt--7">
          </section>
        </div>
        <AuthFooter />
        <Modal
          className="modal-dialog-centered modal-info"
          // contentClassName="bg-gradient-danger"
          contentClassName="mobile-view-modal"
          isOpen={this.state.modalState}
          toggle={() => this.toggleModal()}
        >
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal()}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <h4 className="h2 text-white mb-4">Verify OTP</h4>
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-sms text-primary" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Enter OTP"
                  value={this.state.userInputOTP}
                  type="number"
                  onFocus={() =>
                    this._ismounted && this.setState({ focusedPassword: true })
                  }
                  onBlur={() =>
                    this._ismounted && this.setState({ focusedPassword: false })
                  }
                  name="userInputOTP"
                  onChange={this.handleInputChange}
                />
              </InputGroup>
            </div>
          </div>
          <div>
            <span className='h4 text-white'>
              <center>
                {this.state.errMsg ? this.state.errMsg : null}
              </center>
            </span>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={() => this.verifyOTP()}>
              Verify
            </Button>
          </div>
          <div className="modal-footer">
            <Button color="secondary" size="sm" onClick={() => this.toggleModal()}>
              Cancel
            </Button>
          </div>
        </Modal >
      </>
    );
  }
}

export default ViewImages;
