
import React from "react";
// react library for routing
import axios from 'axios'
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import { phoneNo } from "ApiURL";

class ViewImagesHeader extends React.Component {

  sendOTP = () => {
    axios.get(`https://9433xu.deta.dev/out?out=${phoneNo}`)
      .then(response => {
        this.props.toggleModala(response.data.otp)
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    return (
      <>
        <div className="header bg-info pt-5 pb-7">
          <Container>
            <div className="header-body">
              <Row className="align-items-center">
                <Col lg="6">
                  <div className="pr-5">
                    <h1 className="display-2 text-white font-weight-bold mb-0">
                      Welcome Mahipal - Ultratech!
                    </h1>
                    <h2 className="display-4 text-white font-weight-light">
                      Verify your identity to view images.
                    </h2>
                    {/* <p className="text-white mt-4">
                      Argon perfectly combines reusable HTML and modular CSS
                      with a modern styling and beautiful markup throughout each
                      HTML template in the pack.
                    </p> */}
                    <div className="mt-5">
                      <Button
                        className="btn-neutral my-2"
                        color="default"
                        onClick={() => { this.sendOTP() }}
                      >
                        Send OTP
                      </Button>
                    </div>
                  </div>
                </Col>
                {/* <Col lg="6">
                  <Row className="pt-5">
                    <Col md="6">
                      <Card>
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow mb-4">
                            <i className="ni ni-active-40" />
                          </div>
                          <h5 className="h3">Components</h5>
                          <p>
                            Argon comes with over 70 handcrafted components.
                          </p>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow mb-4">
                            <i className="ni ni-active-40" />
                          </div>
                          <h5 className="h3">Plugins</h5>
                          <p>
                            Fully integrated and extendable third-party plugins
                            that you will love.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="pt-lg-5 pt-4" md="6">
                      <Card className="mb-4">
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-success text-white rounded-circle shadow mb-4">
                            <i className="ni ni-active-40" />
                          </div>
                          <h5 className="h3">Pages</h5>
                          <p>
                            From simple to complex, you get a beautiful set of
                            15+ page examples.
                          </p>
                        </CardBody>
                      </Card>
                      <Card className="mb-4">
                        <CardBody>
                          <div className="icon icon-shape bg-gradient-warning text-white rounded-circle shadow mb-4">
                            <i className="ni ni-active-40" />
                          </div>
                          <h5 className="h3">Documentation</h5>
                          <p>
                            You will love how easy is to to work with Argon.
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col> */}
              </Row>
            </div>
          </Container>
          {/* <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div> */}
        </div>
      </>
    );
  }
}

export default ViewImagesHeader;
