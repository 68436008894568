import React from "react";
import ComponentLogs from 'components/LogsL/ComponentLogs'

import axios from "axios";
import { nodeBaseURL } from "../../ApiURL";

import handleDateRange from "utilFunctions/handleDateRange";
import { connect } from 'react-redux';

import { getConvertedTime, hour24Format, onlyDateFormat } from "utilFunctions/getConvertedTime";

const mapStateToProps = (state) => {
  return {
    outlet: state.outletCode.outletCode,
    time: state.time.time,
    companyServices: state.services.companyServices
  };
}

class EntryExitLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logsData: [],
      logsColumnInfo: [
        {
          dataField: "timestamp",
          text: "Timeframe",
          sort: true,
        },
        {
          dataField: "peopleCount",
          text: "Entry Count",
          sort: false,
        },
      ],
      logsName: 'Entry',
      outlet: props.outlet,
      time: props.time,
      daterange: "Loading...",
      timeZone: props.companyServices[props.outlet] ? props.companyServices[props.outlet].timezone : null
    };
    this._ismounted = true;
  }

  fetchData = (nextProps) => {
    this._ismounted && axios.get(
      nodeBaseURL +
      `entry/list/${nextProps.outlet}/${nextProps.time}`,
      {
        headers: {
          'Authorization': `bearer ${localStorage.getItem('act')}`

        }
      }
    )
      .then((res) => {
        let logs = [];
        let x;
        if (nextProps.time === "day" || nextProps.time === "yesterday") {
          for (const element of Object.entries(res.data)) {
            let x = { timestamp: element[0], count: element[1] };
            logs.push(x);
          }
          x = logs.map((r) => ({
            timestamp: getConvertedTime(r.timestamp, this.state.timeZone, hour24Format),
            peopleCount: r.count,
          }));
        } else if (nextProps.time === "week" || "month") {
          for (const element of Object.entries(res.data)) {
            x = { timestamp: element[0], count: element[1] };
            logs.push(x);
          }
          x = logs.map((r) => ({
            timestamp: getConvertedTime(r.timestamp, this.state.timeZone, onlyDateFormat),
            peopleCount: r.count,
          }));
        }
        this._ismounted && this.setState({ logsData: x.reverse(), daterange: handleDateRange(nextProps.time) });
        if (x.length === 0) {
          this._ismounted && this.setState({
            daterange: `No data is available for ${nextProps.time}`
          })
        }
      })
      .catch(err => {
        this._ismounted && this.setState({ daterange: "Some Error Occured Please contact us at support@oureye.ai" })
      });
  };

  componentDidMount() {
    this.fetchData(this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this._ismounted && this.setState({ daterange: 'Loading...', time: nextProps.time, timeZone: nextProps.companyServices[nextProps.outlet] ? nextProps.companyServices[nextProps.outlet].timezone : null })
      this._ismounted && this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    return (
      <>
        <div>
          <ComponentLogs
            logsData={this.state.logsData}
            logsColumnInfo={this.state.logsColumnInfo}
            logsName={this.state.logsName}
            daterange={this.state.daterange}
          />
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
)(EntryExitLogs);
