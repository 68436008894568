import React from "react";
import ComponentLogs from 'components/LogsL/ComponentLogs'

import axios from "axios";
import { nodeBaseURL } from "../../ApiURL";
import jwt_decode from "jwt-decode";

import handleDateRange from "utilFunctions/handleDateRange";
import { connect } from 'react-redux';
import { getConvertedTime, hour24Format } from "utilFunctions/getConvertedTime";

const mapStateToProps = (state) => {
  let act = jwt_decode(state.act.act)
  return {
    outlet: act.outlets[0],
    time: state.time.time,
    companyServices: state.services.companyServices
  };
}

class KOTLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logsData: [],
      logsColumnInfo: [
        {
          dataField: "timestamp",
          text: "Timeframe",
          sort: true,
        },
        {
          dataField: "order_number",
          text: "Order Number",
          sort: false,
        },
      ],
      logsName: 'Kitchen Order Ticket',
      outlet: props.outlet,
      time: props.time,
      daterange: "Loading...",
      timeZone: props.companyServices[props.outlet] ? props.companyServices[props.outlet].timezone : null
    }
    this._ismounted = true;
  }

  fetchData = (nextProps) => {
    this._ismounted && axios.get(
      nodeBaseURL +
      `kot/list/${nextProps.outlet}/${nextProps.time}`,
      {
        headers: {
          'Authorization': `bearer ${localStorage.getItem('act')}`
        }
      }
    )
      .then((res) => {
        let x = res.data.map((r) => ({
          timestamp: getConvertedTime(r.ts, this.state.timeZone, hour24Format),
          order_number: r.order_number,
          image: r.kot_screenshot
        }))
        this._ismounted && this.setState({ logsData: x.reverse(), daterange: handleDateRange(nextProps.time) });
        if (x.length === 0) {
          this._ismounted && this.setState({
            daterange: `No data is available for ${nextProps.time}`
          })
        }
      })
      .catch(err => {
        this._ismounted && this.setState({ daterange: "Some Error Occured Please contact us at support@oureye.ai" })
      });
  };

  componentDidMount() {
    this.fetchData(this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this._ismounted && this.setState({ daterange: 'Loading...', time: nextProps.time, timeZone: nextProps.companyServices[nextProps.outlet] ? nextProps.companyServices[nextProps.outlet].timezone : null })
      this._ismounted && this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }


  render() {
    return (
      <>
        <ComponentLogs
          logsData={this.state.logsData}
          logsColumnInfo={this.state.logsColumnInfo}
          logsName={this.state.logsName}
          daterange={this.state.daterange}
        />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
)(KOTLogs);