import React from "react";
import {
    Container,
    Card,
} from 'reactstrap';

import Dashheader from "components/Headers/Dashheader";

import jwt_decode from 'jwt-decode';
import { Mixpanel } from "../../../Mixpanel/mixpanel";

import { connect } from 'react-redux';
import CALogs from "components/CustomerAttended/CALogs";
import CustomerAttendedGraph from "components/DashboardGraphs/CustomerAttendedGraph";

const mapStateToProps = state => {
    return {
        outlet: state.outletCode.outletCode,
        time: state.time.time,
    };
};

class CustomerAttendedTab extends React.Component {
    constructor(props) {
        super(props);
        Mixpanel.track('Customers Attended Tab', { distinct_id: jwt_decode(localStorage.getItem('act')).email, email: jwt_decode(localStorage.getItem('act')).email });
        Mixpanel.time_event('Customers Attended Tab Time', { distinct_id: jwt_decode(localStorage.getItem('act')).email, email: jwt_decode(localStorage.getItem('act')).email });
    }
    componentWillUnmount() {
        Mixpanel.track('Customers Attended Tab Time', { distinct_id: jwt_decode(localStorage.getItem('act')).email, email: jwt_decode(localStorage.getItem('act')).email });
    }
    render() {
        return (
            <>
                <Dashheader title="Customers Attended" />
                <Container className="mt--6" fluid>
                    <Card>
                        <CustomerAttendedGraph />
                    </Card>
                    <CALogs />
                </Container>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
)(CustomerAttendedTab);