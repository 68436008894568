import React from "react";
import ComponentLogs from 'components/LogsL/ComponentLogs';

import axios from "axios";
import { nodeBaseURL } from "../../ApiURL";

import handleDateRange from "utilFunctions/handleDateRange";
import { connect } from 'react-redux';
import { getConvertedTime, hour24Format, onlyDateFormat } from "utilFunctions/getConvertedTime";

const mapStateToProps = (state) => {
  return {
    outlet: state.outletCode.outletCode,
    time: state.time.time,
    companyServices: state.services.companyServices,
  };
};

const getPPEFeatures = (services) => {
  let a = [
    {
      dataField: "timestamp",
      text: "Timeframe",
      sort: true,
    },
  ];
  let glove = {
    dataField: "glove",
    text: "Glove Deviation",
    sort: true,
  };
  let hairnet = {
    dataField: "hairnet",
    text: "Hairnet Deviation",
    sort: true,
  };
  let mask = {
    dataField: "mask",
    text: "Mask Deviation",
    sort: true,
  };
  if (services.includes("KPPE.GD")) {
    a.push(glove);
  }
  if (services.includes("KPPE.HD")) {
    a.push(hairnet);
  }
  if (services.includes("KPPE.FMD")) {
    a.push(mask);
  }
  return a;
};

class PPECheckLogs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logsData: [],
      logsColumnInfo: [
        {
          dataField: "timestamp",
          text: "Timeframe",
          sort: true,
        }, {
          dataField: "glove",
          text: "Glove Deviation",
          sort: true,
        },
        {
          dataField: "hairnet",
          text: "Hairnet Deviation",
          sort: true,
        },
        {
          dataField: "mask",
          text: "Mask Deviation",
          sort: true,
        }
      ],
      logsName: 'PPE Check',
      outletCode: props.outlet,
      time: props.time,
      daterange: "Loading...",
      outlet: props.outlet,
      companyServices: props.companyServices,
      timeZone: props.companyServices[props.outlet] ? props.companyServices[props.outlet].timezone : null
    };
    this._ismounted = true;
  }

  fetchData = (nextProps) => {
    this._ismounted && axios.get(
      nodeBaseURL +
      `sopDeviation/list/${nextProps.outlet}/${nextProps.time}`,
      {
        headers: {
          'Authorization': `bearer ${localStorage.getItem('act')}`
        }
      }
    )
      .then((res) => {
        var x;
        if (nextProps.time === "day" || nextProps.time === 'yesterday') {
          x = res.data.map((r) => ({
            timestamp: getConvertedTime(r.ts, this.state.timeZone, hour24Format),
            glove: r.gloves,
            hairnet: r.hairnet,
            mask: r.mask,
          }));
        } else if (nextProps.time === "week" || "month") {
          x = res.data.map((r) => ({
            timestamp: getConvertedTime(r.ts, this.state.timeZone, onlyDateFormat),
            glove: r.gloves,
            hairnet: r.hairnet,
            mask: r.mask,
          }));
        }
        this._ismounted && this.setState({ logsData: x.reverse(), daterange: handleDateRange(this.state.time) });
        if (x.length === 0) {
          this._ismounted && this.setState({
            daterange: `No data is available for ${nextProps.time}`
          });
        }
      })
      .catch(err => {
        this._ismounted && this.setState({ daterange: "Some Error Occured Please contact us at support@oureye.ai" });
      });
  };

  componentDidMount() {
    this.fetchData(this.state);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      this._ismounted && this.setState({
        daterange: 'Loading...',
        time: nextProps.time,
        outletCode: nextProps.outlet,
        companyServices: nextProps.companyServices,
      });
      this._ismounted && this.fetchData(nextProps);
    }
  }

  componentWillUnmount() {
    this._ismounted = false;
  }

  render() {
    return (
      <>
        <div>
          <ComponentLogs
            logsData={this.state.logsData}
            logsColumnInfo={this.state.companyServices[this.state.outletCode] ? getPPEFeatures(this.state.companyServices[this.state.outlet].services) : [
              {
                dataField: "timestamp",
                text: "Timeframe",
                sort: true,
              },
            ]}
            logsName={this.state.logsName}
            daterange={this.state.daterange}
          />
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
)(PPECheckLogs);