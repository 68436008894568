import React from "react";
import MediaLogs from 'components/LogsL/MediaLogs';

import axios from "axios";
import { nodeBaseURL } from "../../ApiURL";

import handleDateRange from "utilFunctions/handleDateRange";
import { connect } from 'react-redux';
import { getConvertedTime } from "utilFunctions/getConvertedTime";
import { hour24Format } from "utilFunctions/getConvertedTime";

const mapStateToProps = (state) => {
    return {
        outlet: state.outletCode.outletCode,
        time: state.time.time,
        outletCameraDetails: state.outletCode.outletCameraDetails,
        companyServices: state.services.companyServices
    };
};

class BagPrintLogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logsData: [],
            logsColumnInfo: [
                {
                    dataField: "timestamp",
                    text: "Timeframe",
                    sort: true,
                },
                {
                    dataField: "camera_location",
                    text: "Camera Location",
                    sort: false,
                },
                {
                    dataField: "bag_print",
                    text: "Packaging Print Status",
                    sort: false,
                },
                {
                    dataField: "media",
                    text: "Media",
                    sort: false,
                },
            ],
            logsName: 'Packaging Print',
            outlet: props.outlet,
            time: props.time,
            daterange: "Loading...",
            timeZone: props.companyServices[props.outlet] ? props.companyServices[props.outlet].timezone : null
        };
        this._ismounted = true;
    }

    fetchData = (nextProps) => {
        axios.get(
            nodeBaseURL +
            `bag_print/logs/${nextProps.outlet}/${nextProps.time}`,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('act')}`

                }
            }
        )
            .then(res => {
                let x = res.data.map((item) => {
                    return {
                        'timestamp': getConvertedTime(item.timestamp, this.state.timeZone, hour24Format),
                        'camera_location': this.props.outletCameraDetails[item.camera_location],
                        'bag_print': (item.bag_print).toString().toUpperCase(),
                        'media': item.screenshot
                    };
                });
                this._ismounted && this.setState({ logsData: x.reverse(), daterange: handleDateRange(nextProps.time) });
                if (x.length === 0) {
                    this._ismounted && this.setState({
                        daterange: `No data is available for ${nextProps.time}`
                    });
                }
            })
            .catch(err => {
                this._ismounted && this.setState({ daterange: "Some Error Occured Please contact us at support@oureye.ai" });
            });
    };

    componentDidMount() {
        this.fetchData(this.state);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this._ismounted && this.setState({ daterange: 'Loading...', })
            this.fetchData(nextProps);
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }


    render() {
        return (
            <>
                <MediaLogs
                    logsData={this.state.logsData}
                    logsColumnInfo={this.state.logsColumnInfo}
                    logsName={this.state.logsName}
                    daterange={this.state.daterange}
                />
            </>
        );
    }
}

export default connect(
    mapStateToProps,
)(BagPrintLogs);