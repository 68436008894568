import React from "react";
import MediaLogs from 'components/LogsL/MediaLogs';

import axios from "axios";
import { nodeBaseURL } from "../../ApiURL";

import handleDateRange from "utilFunctions/handleDateRange";
import { connect } from 'react-redux';
import { getOutletTime } from "utilFunctions/getOutletTime";

const mapStateToProps = (state) => {
    return {
        outlet: state.outletCode.outletCode,
        time: state.time.time,
        outletCameraDetails: state.outletCode.outletCameraDetails
    };
};

class CALogs extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            logsData: [],
            logsColumnInfo: [
                {
                    dataField: "timestamp",
                    text: "Timeframe",
                    sort: true,
                },
                // {
                //     dataField: "attended",
                //     text: "Attended",
                //     sort: false,
                // },
                {
                    dataField: "cam_location",
                    text: "Camera Location",
                    sort: false,
                },
                {
                    dataField: "customers",
                    text: "No. of Customers",
                    sort: false,
                },
                {
                    dataField: "employees",
                    text: "No. of Employees",
                    sort: false,
                },
                {
                    dataField: "duration",
                    text: "Duration (in sec)",
                    sort: false,
                },
                {
                    dataField: "media",
                    text: "Media",
                    sort: false,
                },
            ],
            logsName: 'Customers Attended',
            outlet: props.outlet,
            time: props.time,
            daterange: "Loading...",
        };
        this._ismounted = true;
    }

    fetchData = (nextProps) => {
        axios.get(
            nodeBaseURL +
            `customers_attended/logs/${nextProps.outlet}/${nextProps.time}`,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('act')}`

                }
            }
        )
            .then(res => {
                let x = res.data.map((el) => {
                    return {
                        'timestamp': getOutletTime(el.timestamp),
                        // 'attended': el.attended,
                        'cam_location': this.props.outletCameraDetails[el.camera_location],
                        'customers': el.no_customers,
                        'employees': el.no_employees,
                        'duration': el.duration.toFixed(0),
                        'media': el.screenshot
                    };
                });
                this._ismounted && this.setState({ logsData: x.reverse(), daterange: handleDateRange(nextProps.time) });
                if (x.length === 0) {
                    this._ismounted && this.setState({
                        daterange: `No data is available for ${nextProps.time}`
                    });
                }
            })
            .catch(err => {
                this._ismounted && this.setState({ daterange: "Some Error Occured Please contact us at support@oureye.ai" });
            });
    };

    componentDidMount() {
        this.fetchData(this.state);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this._ismounted && this.setState({ daterange: 'Loading...', })
            this.fetchData(nextProps);
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }


    render() {
        return (
            <>
                <MediaLogs
                    logsData={this.state.logsData}
                    logsColumnInfo={this.state.logsColumnInfo}
                    logsName={this.state.logsName}
                    daterange={this.state.daterange}
                />
            </>
        );
    }
}

export default connect(
    mapStateToProps,
)(CALogs);