import React, { Component } from "react";

import { Card, CardBody, Row, CardTitle, Col, Modal, Button } from "reactstrap";

import axios from "axios";
import handleDateRange from 'utilFunctions/handleDateRange';

import { connect } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { nodeBaseURL } from "ApiURL";
import Chart from "./Chart";

const mapStateToProps = (state) => {
    return {
        act: jwt_decode(state.act.act),
        time: state.time.time,
        outlet: state.outletCode.outletCode
    };
};

const emojiList = {
    HAPPY: '😁',
    SAD: '😢',
    ANGRY: '😡',
    CONFUSED: '😕',
    DISGUSTED: '🤮',
    SURPRISED: '😮',
    CALM: '😌',
    FEAR: '😱'
};

const getTop3EmotionsList = (apiData) => {
    var objKeys = Object.keys(apiData)
    var listOfEmotionsDescSort = objKeys.map(item => {
        return ({
            key: item,
            value: apiData[item],
            emoji: emojiList[item]
        })
    })
        .sort((a, b) => {
            return b.value - a.value;
        });
    return listOfEmotionsDescSort.slice(0, 3)
}

class CSICard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exampleModal: false,
            time: props.time,
            daterange: handleDateRange(props.time),
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            emotionData: {},
        };
        this._ismounted = true;
    }

    fetchData = (nextProps) => {
        this._ismounted && axios.get(
            nodeBaseURL +
            `customer_emotions/aggregate/${nextProps.outlet}/${nextProps.time}`,
            {
                headers: {
                    'Authorization': `bearer ${localStorage.getItem('act')}`
                }
            }
        )
            .then((res) => {
                this.setState({ emotionData: res.data });
            })
            .catch(console.log);
    };

    componentDidMount() {
        this.fetchData(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this._ismounted && this.setState({
                time: nextProps.time,
                daterange: handleDateRange(nextProps.time),
                outlet: nextProps.outlet
            });
            this.fetchData(nextProps);
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    toggleModal = (state) => {
        this._ismounted && this.setState({
            [state]: !this.state[state],
        });
    };

    render() {
        return (
            <Card className="card-stats">
                <CardBody>
                    <Row>
                        <div className="col">
                            <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                                Customer Satisfaction
                            </CardTitle>
                            <div>
                                <Row className="mt-4 mb-2">
                                    {
                                        getTop3EmotionsList(this.state.emotionData).map((item, index) => {
                                            return (<Col md="4" className="d-flex align-items-center" key={index}>
                                                <span className="h1 m-0 text-red">
                                                    {item.emoji}
                                                </span>
                                                <p style={{ margin: '0 0 0 5px' }} className="h3">{item.value}</p>
                                            </Col>)
                                        })
                                    }
                                </Row>
                            </div>
                        </div>
                        <Col className="col-auto">
                            <div className="icon icon-shape bg-gradient-primary text-white rounded-circle shadow">
                                <i className="fas fa-smile" />
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-2 mb-0 text-sm">
                        <div className="mt-1">
                            <Button
                                color="secondary"
                                outline
                                type="button"
                                size="sm"
                                onClick={() => this.toggleModal("exampleModal")}
                            >
                                <span className="text-info">
                                    <i className="fa fa-question-circle" /> Learn More
                                </span>{" "}
                            </Button>
                        </div>
                        <Modal
                            className="modal-dialog-centered"
                            isOpen={this.state.exampleModal}
                            toggle={() => this.toggleModal("exampleModal")}
                            size="lg"
                        >
                            <div className="modal-header">
                                <span>
                                    <h4 className="modal-title" id="exampleModalLabel">
                                        Customer Satisfaction
                                    </h4>
                                    <br />
                                    <h4 className="h4 text-muted">({this.state.daterange})</h4>
                                </span>
                                <button
                                    aria-label="Close"
                                    className="close"
                                    data-dismiss="modal"
                                    type="button"
                                    onClick={() => this.toggleModal("exampleModal")}
                                >
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Chart />
                            </div>
                        </Modal>
                    </div>
                </CardBody>
            </Card>
        );
    }
}

//export default PeopleCount;

export default connect(
    mapStateToProps,
)(CSICard);